import Sheet from '@/structs/sheet.js'
import { Filter } from '@/structs/filter.js'

class Commands {
  constructor (options) {
    for (const key in options) {
      this[key] = options[key]
    }
  }

  restoreIdentitiesFromLocal () {
    return new Promise((resolve, reject) => {
      this.identitiesAdapter.restore()
        .then((identities) => {
          identities.forEach((identity) => {
            (identity.sources || []).forEach((source) => {
              source.identityId = identity.id
            })

            identity.sheets.forEach((sheet) => {
              sheet.identityId = identity.id
              sheet.dateFilter = Filter.fromObj(sheet.dateFilter)
              sheet.typeFilter = Filter.fromObj(sheet.typeFilter)
              sheet.filters = sheet.filters
                .filter((filter) => filter)
                .map((filter) => Filter.fromObj(filter))
            })

            this.state.add('identities', [identity])
            this.state.add('sources', identity.sources || [])
            this.state.add('sheets', identity.sheets || [])
          })

          resolve()
        })
        .catch(reject)
    })
  }

  addSeedIdentityIfRequired () {
    if (!this.queries.findAllIdentities().length) {
      this.addIdentity({ name: 'Dunder Mifflin' })

      const identity = this.queries.findAllIdentities()[0]
      this.addSourceForIdentity(identity, { type: 'DunderMifflin', processor: 'PlainText' })
    }
  }

  addSeedSheetIfRequired (identity) {
    if (!this.queries.findAllSheets(identity.id).length) {
      Sheet.SHEETS.forEach((sheet) => {
        this.addSheetForIdentity(identity, sheet)
      })
    }
  }

  addIdentity (data) {
    const identity = this.state.add('identities', [data])[0]

    this.addSeedSheetIfRequired(identity)
    this._saveIdentity(identity)

    return identity
  }

  updateIdentityName (identity, name) {
    identity.name = name
    this._saveIdentity(identity)
  }

  _saveIdentity (identity) {
    this.identitiesAdapter.set(identity.id, {
      id: identity.id,
      name: identity.name,
      sources: this.queries.findAllSources(identity.id).map((source) => {
        return {
          id: source.id,
          type: source.type,
          processor: source.processor,
          config: JSON.parse(JSON.stringify(source.config || {})),
          raw: source.raw,
          updatedAt: source.updatedAt
        }
      }),
      sheets: this.queries.findAllSheets(identity.id).map((sheet) => {
        return {
          id: sheet.id,
          name: sheet.name,
          ordinal: sheet.ordinal,
          typeFilter: JSON.parse(JSON.stringify(sheet.typeFilter)),
          dateFilter: JSON.parse(JSON.stringify(sheet.dateFilter)),
          filters: JSON.parse(JSON.stringify(sheet.filters || []))
        }
      })
    })
  }

  removeIdentity (identity) {
    this.identitiesAdapter.remove(identity.id)
    this.state.removeAll('identities', [identity])
  }

  resetTransactionsForIdentity (identity) {
    const transactions = this.queries.findAllTransactions(identity.id)

    this.removeTransactions(transactions)
  }

  addTransactionsForIdentity (identity, transactions) {
    transactions.forEach((transaction) => {
      transaction.identityId = identity.id
    })

    this.state.add('transactions', transactions)
  }

  removeTransactions (transactions) {
    this.state.removeAll('transactions', transactions)
  }

  addPeriodicTransactionsForIdentity (identity, periodics) {
    periodics.forEach((periodic) => {
      periodic.identityId = identity.id
    })

    this.state.add('periodics', periodics)
  }

  rebuildPeriodicTransactionsForFilters (identity, dateFilter) {
    const existing = this.queries.findAllPeriodicTransactions()
    this.state.removeAll('transactions', existing)
  }

  resetAccountsForIdentity (identity) {
    const accounts = this.queries.findAllAccounts(identity.id)

    this.state.removeAll('accounts', accounts)
  }

  addAccountsForIdentity (identity, accounts) {
    accounts.forEach((account) => {
      account.identityId = identity.id
    })

    this.state.add('accounts', accounts)
  }

  addSourceForIdentity (identity, data) {
    data.identityId = identity.id

    const source = this.state.add('sources', [data])[0]

    this._saveIdentity(identity)

    return source
  }

  saveSourceForIdentity (identity, source, data) {
    Object.assign(source, data)
    source.updatedAt = Date.now()
    this._saveIdentity(identity)
  }

  removeSourceForIdentity (identity, source) {
    this.state.removeAll('sources', [source])
    this._saveIdentity(identity)
  }

  addSheetForIdentity (identity, data) {
    data.identityId = identity.id

    const sheet = this.state.add('sheets', [data])[0]

    this._saveIdentity(identity)

    return sheet
  }

  saveSheetForIdentity (identity, sheet, data) {
    Object.assign(sheet, data)
    sheet.updatedAt = Date.now()
    this._saveIdentity(identity)
  }

  removeSheetForIdentity (identity, sheet) {
    this.state.removeAll('sheets', [sheet])
    this._saveIdentity(identity)
  }

  saveSheetOrderForIdentity (identity, sheetIds) {
    for (var i = 0; i < sheetIds.length; i++) {
      const sheet = this.queries.findSheetForIdentity(identity.id, sheetIds[i])

      if (sheet) {
        sheet.ordinal = i
      }
    }

    this._saveIdentity(identity)
  }

  async fetchSource (identity, source) {
    const adapter = this.queries.sourceFetchAdapter(source)

    return await adapter.fetch()
  }

  async processSourceContent (identity, source, content) {
    const adapter = this.queries.sourceProcessorAdapter(source)

    return await adapter.process(content)
  }

  async refreshIdentitySource (identity, source) {
    try {
      source.isLoading = true

      source.raw = await this.fetchSource(identity, source)
      const data = await this.processSourceContent(identity, source, source.raw)

      source.isLoading = false

      return data
    } catch (e) {
      source.isLoading = false
      throw e
    }
  }

  refreshIdentitySources (identity) {
    const sources = this.queries.findAllSources(identity.id)

    return Promise.all(sources.map((s) => this.refreshIdentitySource(identity, s)))
  }

  regeneratePeriodicTransactions (identity, dateFilter) {
    const periodicTransactions = this.queries.findAllPeriodicTransactions(identity.id)
    this.removeTransactions(periodicTransactions)

    if (!dateFilter.startAt || !dateFilter.endAt) {
      return
    }

    this.generatePeriodicTransactions(identity, dateFilter)
  }

  generatePeriodicTransactions (identity, dateFilter) {
    const periodics = this.queries.findAllPeriodics(identity.id)
    const allPeriodicTransactions = periodics.reduce((acc, periodic) => {
      const transactions = periodic.transactionsFor(dateFilter.startAt, dateFilter.endAt)
      transactions.forEach((t) => { t.periodicName = periodic.name })
      return acc.concat(transactions)
    }, [])

    allPeriodicTransactions.forEach((periodicTransaction) => {
      const existingPostings = this.queries
        .findAllTransactionsBetween(identity.id, periodicTransaction.periodicRange.startAt, periodicTransaction.periodicRange.endAt)
        .reduce((acc, t) => acc.concat(t.getPostings()), [])

      periodicTransaction.postings.forEach((periodicPosting) => {
        const amount = periodicPosting.amount
        const existingSum = existingPostings
          .filter((posting) => !posting.periodicName)
          .filter((posting) => periodicPosting.currency === posting.currency)
          .filter((posting) => periodicPosting.account === posting.account)
          .reduce((sum, posting) => {
            return sum + posting.amount
          }, 0)

        if (Math.abs(amount) > Math.abs(existingSum)) {
          periodicPosting.amount = amount - existingSum
        } else {
          periodicPosting.amount = 0
        }
      })
    })

    this.addTransactionsForIdentity(identity, allPeriodicTransactions)
  }

  reset () {
    this.state.reset()
  }

  download (identity) {
    const content = this.queries.identityToPlainText(identity)
    const blob = this.blob([content], { type: 'text/plain;charset=utf-8' })

    this.saveAs(blob, `${identity.id}.txt`)
  }
}

export default Commands
