<template>
  <Panel
    v-if="transaction"
    :back="{ name: 'transactions', params: { identity_id: app.identity.id } }"
  >
    <template #content>
      <div
        id="transaction-invoice"
        class="print:text-sm"
      >
        <div class="float-left print:hidden">
          <select
            v-model="summaryType"
            class="block mb-3 bg-neutral-100 dark:bg-neutral-800 text-neutral-400 dark:text-neutral-600"
          >
            <option value="asPayee">
              Viewing as {{ app.queries.fullPayeeAddress(app.identity, transaction) }}
            </option>
            <option value="asAccount">
              Viewing as {{ app.queries.toAccountAddress(app.identity, transaction).split('<br>')[0] }}
            </option>
          </select>

          <button
            class="rounded-md bg-indigo-500 px-3 py-2 text-sm text-white dark:text-neutral-700"
            @click="print"
          >
            Print
          </button>
        </div>

        <p class="text-right">
          <span v-html="app.queries.toAccountAddress(app.identity, transaction)" />
        </p>

        <p>
          <span v-if="transaction.referenceId">
            Invoice No: {{ transaction.referenceId }}<br>
          </span>
          Date: {{ app.queries.prettyDate(transaction.date) }}<br>
        </p>

        <br>

        <p
          v-if="app.queries.transactionCleared(transaction) && summaryType === 'asAccount'"
          class="float-right"
        >
          PAID
        </p>

        <p>
          <span
            class="block"
            v-html="app.queries.fullPayeeAddress(app.identity, transaction)"
          />
        </p>

        <br>

        <table class="w-full">
          <tbody>
            <tr
              v-for="posting in postings"
              :key="posting"
            >
              <td v-html="posting.comment || app.queries.fullAccountName(app.identity, posting.account)" />
              <td class="text-right">
                {{ app.queries.formatAmount(-posting.amount, posting.currency) }}
              </td>
            </tr>
            <tr>
              <th colspan="2">
                <br>
              </th>
            </tr>
            <tr>
              <td>
                <strong>Total</strong>
              </td>
              <td class="text-right">
                <strong>{{ app.queries.formatAmount(total, postings[0].currency) }}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </Panel>
</template>

<script>
import Panel from '@/app/components/panel/component.vue'
import Posting from '@/parse-and-play/posting.js'

export default {
  components: {
    Panel
  },

  props: ['app'],

  data () {
    return {
      summaryType: 'asPayee',
      summaryTypes: ['asPayee', 'asAccount']
    }
  },

  computed: {
    transaction () {
      return this.app.queries.findTransactionForIdentity(this.app.identity.id, this.$route.params.transaction_id)
    },

    postings () {
      const p = this.transaction.postings
        .filter((p) => !p.elided)

      if (this.summaryType === 'asAccount') {
        return p.map((p) => new Posting(p.amount, p.currency, p.account, p.elided, p.value, p.comment))
      } else if (this.summaryType === 'asPayee') {
        return p.map((p) => new Posting(p.amount * -1, p.currency, p.account, p.elided, p.value, p.comment))
      } else {
        return []
      }
    },

    total () {
      const t = this.postings
        .reduce((sum, posting) => sum + posting.amount, 0)
        .toString()

      return t.indexOf('-') !== -1 ? `${t.replace(/^-/, '')}` : `-${t}`
    }
  },

  mounted () {
    if (this.transaction?.referenceId) {
      this.summaryType = this.transaction.referenceId ? 'asAccount' : 'asPayee'
    }
  },

  methods: {
    print () {
      window.print()
    },

    toggleSummaryType () {
      const index = this.summaryTypes.indexOf(this.summaryType)

      this.summaryType = this.summaryTypes[index + 1] || this.summaryTypes[0]
    }
  }
}
</script>

<style lang="scss">
  @media print {
    .main-content {
      display: none
    }
  }
</style>
