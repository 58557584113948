import DunderMifflin from '@/adapters/fetchers/dunder-mifflin.js'
import S3 from '@/adapters/fetchers/s3.js'
import Url from '@/adapters/fetchers/url.js'
import Git from '@/adapters/fetchers/git.js'
import PlainText from '@/adapters/processors/plain-text.js'
import PlainTextRandomizer from '@/adapters/processors/plain-text-randomizer.js'
import PlainTextExporter from '@/adapters/exporters/plain-text.js'
import timeAgoInWords from 'time_ago_in_words'
import { formatAmount, floatToDateString, dateToInteger } from '@/parse-and-play/utils.js'

const buildSourceFetchAdapter = (source) => {
  switch (source.type) {
    case 'DunderMifflin':
      return DunderMifflin
    case 'Git':
      return Git
    case 'S3':
      return S3
    case 'Url':
      return Url
    default:
      throw new Error(`Invalid fetch adapter: ${source.type}`)
  }
}

const buildSourceProcessorAdapter = (source) => {
  switch (source.processor) {
    case 'PlainText':
      return PlainText
    case 'PlainTextRandomizer':
      return PlainTextRandomizer
    default:
      throw new Error(`Invalid processor adapter: ${source.processor}`)
  }
}

// const addToAllSplats = (arr, account) => {
//   const splat = account.split(':')
//
//   splat.forEach((item, index) => {
//     const str = splat.slice(0, index + 1).join(':')
//
//     if (arr.indexOf(str) === -1) {
//       arr.push(str)
//     }
//   })
// }

class Queries {
  constructor (options) {
    for (const key in options) {
      this[key] = options[key]
    }
  }

  findAllIdentities () {
    return this.state.findAll('identities')
  }

  findIdentityById (id) {
    return this.state.find('identities', (identity) => identity.id === id)
  }

  findAllSources (identityId) {
    return this.state.findAll('sources', (source) => source.identityId === identityId)
  }

  findSourceForIdentity (identityId, sourceId) {
    return this.findAllSources(identityId).find((source) => source.id === sourceId)
  }

  findAllSheets (identityId) {
    return this.state
      .findAll('sheets', (sheet) => sheet.identityId === identityId)
      .sort((a, b) => a.ordinal - b.ordinal)
  }

  findSheetForIdentity (identityId, sheetId) {
    return this.findAllSheets(identityId).find((sheet) => sheet.id === sheetId)
  }

  findAllTransactions (identityId) {
    return this.state
      .findAll('transactions', (transaction) => transaction.identityId === identityId)
      .sort((a, b) => a.date - b.date)
  }

  findAllTransactionsBetween (identityId, startAt, endAt) {
    startAt = dateToInteger(startAt)
    endAt = dateToInteger(endAt)

    return this.findAllTransactions(identityId)
      .filter((t) => t.date >= startAt && t.date <= endAt)
  }

  findAllPeriodics (identityId) {
    return this.state
      .findAll('periodics', (periodic) => periodic.identityId === identityId)
  }

  findAllPeriodicTransactions (identityId) {
    return this.findAllTransactions(identityId).filter((transaction) => !!transaction.periodicName)
  }

  findTransactionForIdentity (identityId, transactionId) {
    return this.findAllTransactions(identityId).find((transaction) => transaction.id === transactionId)
  }

  sourceFetchAdapter (source) {
    const Adapter = buildSourceFetchAdapter(source)

    return new Adapter(source.config)
  }

  sourceProcessorAdapter (source) {
    const Adapter = buildSourceProcessorAdapter(source)

    return new Adapter(source.config)
  }

  getSourceName (source) {
    return this.sourceFetchAdapter(source).name
  }

  getSourceModes (source) {
    return this.sourceFetchAdapter(source).modes
  }

  findAllAccounts (identityId) {
    return this.state
      .findAll('accounts', (account) => account.identityId === identityId)
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  findAccountForIdentity (identityId, accountName) {
    return this.findAllAccounts(identityId).find((account) => account.name === accountName)
  }

  findAllPayees (transactions) {
    const payees = transactions.reduce((arr, transaction) => {
      if (arr.indexOf(transaction.payee) === -1) {
        arr.push(transaction.payee)
      }

      return arr
    }, [])

    return [...new Set(payees)].sort()
  }

  formatAmount (amount, currency) {
    return formatAmount(amount, currency)
  }

  prettyDate (date) {
    return floatToDateString(date)
  }

  prettyTime (date) {
    return timeAgoInWords(new Date(date))
  }

  accountName (accountKey, accounts) {
    if (accounts && accounts[accountKey] && accounts[accountKey].address) {
      return accounts[accountKey].address.split(/\n/)[0]
    }

    return accountKey
  }

  toAccountAddress (identity, transaction) {
    const accountName = transaction.postings[transaction.postings.length - 1].account

    return this.accountAddress(identity, accountName)
  }

  accountAddress (identity, accountName) {
    const account = this.findAccountForIdentity(identity.id, accountName)

    return `${account ? account.address : accountName}`.replace(/\n/g, '<br>')
  }

  fullPayeeAddress (identity, transaction) {
    return this.payeeAddress(transaction)
  }

  fullAccountName (identity, accountName) {
    const account = this.findAccountForIdentity(identity.id, accountName)

    return `${account ? account.address : accountName}`.split(/\n/)[0] || (account ? account.name : accountName)
  }

  shortFilename (str) {
    return str.split('/').pop()
  }

  transactionTotal (transaction) {
    const sum = transaction.sum()
    const key = Object.keys(sum)[Object.keys(sum).length - 1]

    return this.formatAmount(Object.values(sum[key])[0], Object.keys(sum[key])[0])
  }

  transactionCleared (transaction) {
    return transaction.cleared
  }

  payeeAddress (transaction) {
    return transaction.payee.replace(/\([\d]+\)/, '').trim()
  }

  receiverAddress (transaction, accounts) {
    const accountKey = transaction.postings[transaction.postings.length - 1].account

    if (accounts[accountKey] && accounts[accountKey].address) {
      return accounts[accountKey].address.replace(/\n/g, '<br>')
    }

    return accountKey
  }

  income (postings) {
    return postings.filter((p) => p.amount <= 0)
  }

  expenses (postings) {
    return postings.filter((p) => p.amount > 0)
  }

  findAllRecurrences (file) {
    file.recurrences = file.recurrences || []

    return file.recurrences
  }

  formatPercent (percent) {
    if (percent === Infinity) { return '-' }

    return `${percent.toFixed(0)}%`
  }

  identityToPlainText (identity) {
    const accounts = this.findAllAccounts(identity.id)
    const transactions = this.findAllTransactions(identity.id)
    const periodics = this.findAllPeriodics(identity.id)
    const exporter = new PlainTextExporter(accounts, transactions, periodics)

    return exporter.toString()
  }
}

export default Queries
