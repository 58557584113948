<template>
  <div>
    <table class="w-full divide-y divide-neutral-200 dark:divide-neutral-800">
      <thead>
        <tr>
          <th class="py-3.5 pl-4 pr-3 text-left text-neutral-400 sm:pl-6 lg:pl-8" />
          <th class="px-3 py-3.5 text-left text-neutral-400">
            Date
          </th>
          <th class="px-3 py-3.5 text-left text-neutral-400">
            Payee
          </th>
          <th class="px-3 py-3.5 text-left text-neutral-400">
            Account
          </th>
          <th class="px-3 py-3.5 text-right text-neutral-400">
            Amount
          </th>
          <th class="px-3 py-3.5 text-left text-neutral-400" />
        </tr>
      </thead>
      <tbody
        v-if="transactions.length > 0"
        class="divide-y divide-neutral-200 dark:divide-neutral-800 text-white"
      >
        <template
          v-for="transaction in transactions"
          :key="`transaction-${transaction}-register`"
        >
          <tr
            v-for="(posting, index) in transaction.postings"
            :key="`transaction-${transaction}-posting-${posting}`"
            class="text-neutral-600 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-950"
          >
            <td class="py-2 px-3 pl-8">
              <span v-if="index === 0">
                <span v-if="transaction.cleared">*</span>
                <span
                  v-else
                  class="text-yellow-700"
                >!</span>
              </span>
            </td>
            <td class="py-2 px-3 pl-8">
              <span v-if="index === 0">{{ floatToDateString(transaction.date) }}</span>
            </td>
            <td class="py-2 px-3">
              <span
                v-if="index === 0"
                class="block truncate max-w-64"
                :title="transaction.payee"
              >
                <span v-if="transaction.referenceId">({{ transaction.referenceId }})</span>
                {{ transaction.payee }}
              </span>
            </td>
            <td class="py-2 px-3">
              <span
                class="block text-left truncate max-w-64"
                style="direction: rtl;"
                :title="posting.account"
              >
                {{ posting.account }}
              </span>
            </td>
            <td class="py-2 px-3 text-right">
              {{ formatAmount(posting.amount, posting.currency) }}
            </td>
            <td class="py-2 px-3 pr-8">
              <router-link
                v-if="index === 0"
                :to="{ name: 'transaction', params: { identity_id: app.identity.id, transaction_id: transaction.id } }"
              >
                View
              </router-link>
            </td>
          </tr>
        </template>
      </tbody>
      <tbody v-else>
        <tr>
          <td
            class="text-center p-8"
            colspan="6"
          >
            <p class="grid h-32 place-items-center">
              No transactions found.
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { formatAmount, floatToDateString } from '@/parse-and-play/utils'

export default {
  props: ['app', 'sheet', 'transactions'],
  methods: {
    formatAmount,
    floatToDateString
  }
}
</script>
